// when not used vite -> need update env vars
export const isDev = () => import.meta.env.MODE == "development";

export const stringIsEmpty = (text?: string | null): boolean =>
  typeof text !== "string" || !text.split(" ").join("").length;

/**
 * Checks if a string contains only letters, numbers, and spaces.
 *
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string is valid, otherwise false.
 */
export const isValidString = (str: string): boolean => {
  const regex = /^[a-zA-Z0-9 ]*$/;
  return regex.test(str);
};

/**
 * Checks if a string contains only letters and numbers.
 *
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string is valid, otherwise false.
 */
export const isValidStringWithoutSpace = (str: string): boolean => {
  const regex = /^[a-zA-Z0-9]*$/; // Removed space from regex
  return regex.test(str);
};

/**
 * Checks if the given string contains only digits and is within the specified length range.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.str - The string to be checked.
 * @param {number} [params.minLength] - The minimum length of the string (optional, default is 1).
 * @param {number} [params.maxLength=16] - The maximum length of the string (optional, default is 16).
 * @returns {boolean} - Returns true if the string is valid, otherwise false.
 */
export const isValidDigitString = ({
  str,
  minLength,
  maxLength = 16,
}: {
  str: string;
  minLength?: number;
  maxLength?: number;
}): boolean => {
  const min = typeof maxLength === "number" ? minLength ?? 1 : null;
  const regex = new RegExp(maxLength ? `^\\d{${min},${maxLength}}$` : /^\d+$/);
  return regex.test(str);
};
